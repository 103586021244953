import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"

import { breakpoints } from "../parameters/theme"

const Container = styled.div`
  .section-posts {
    margin-top: 2em;
    padding: 1em;

    h2 {
      font-size: 1.5em;
    }

    .posts {
      display: grid;
      grid-gap: 1em 1em;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 1em;
    }
  }

  .section-big-quote {
    background-image: linear-gradient(rgba(0, 50, 0, 0.5), rgba(0, 50, 0, 0.5));
    margin-top: 2em;
    position: relative;
  }

  .quote-background {
    left: 0;
    height: 100%;
    max-width: unset;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    .gatsby-image-wrapper {
      height: 100%;

      ::after {
        background: transparent no-repeat 50% scroll;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(0, 50, 0, 0.5)),
          to(rgba(0, 50, 0, 0.5))
        );
        background-image: linear-gradient(
          rgba(0, 50, 0, 0.5),
          rgba(0, 50, 0, 0.5)
        );
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .quote {
    position: relative;
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .section-posts .posts {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .section-posts .posts {
      display: block;
    }

    .posts .post:not(:first-of-type) {
      margin-top: 1em;
    }
  }
`

const IndexPage = ({
  data: { allContentfulArticle, quoteBackground, allContentfulInterview },
  location,
}) => {
  const articlesByCategory = allContentfulArticle.edges.reduce(
    (acc, { node: article }) => {
      if (acc[article.category]) {
        acc[article.category].push(article)
      } else {
        acc[article.category] = [article]
      }

      return acc
    },
    {}
  )

  return (
    <Layout title="Athlegan" location={location} pageType="home" nodeData={{}}>
      <Container>
        <section className="section-posts">
          <h2>
            Latest Articles (<Link to="/articles">see all</Link>)
          </h2>
          <section className="posts">
            {allContentfulArticle.edges.slice(0, 3).map(({ node: article }) => (
              <div
                key={article.slug}
                className="post post-cover-title post-cover-big"
              >
                <Link to={`/${article.slug}`} title={article.title}>
                  {article.titleImage ? (
                    <Img
                      fluid={article.titleImage ? article.titleImage.fluid : ``}
                      alt={article.title}
                    />
                  ) : null }
                </Link>
                <h3>{article.title}</h3>
              </div>
            ))}
          </section>
        </section>

        {/** Guides */}
        <section className="section-posts">
          <h2>
            Guides <Link to="/articles#guides">→</Link>
          </h2>
          <section className="posts">
            {articlesByCategory["Guides"].slice(0, 3).map(article => (
              <div
                key={article.slug}
                className="post post-cover-title post-cover-big"
              >
                <Link to={`/${article.slug}`} title={article.title}>
                  <Img
                    fluid={article.titleImage ? article.titleImage.fluid : ``}
                    alt={article.title}
                  />
                </Link>
                <h3>{article.title}</h3>
              </div>
            ))}
          </section>
        </section>

        {/** Nutrition */}
        <section className="section-posts">
          <h2>
            Nutrition <Link to="/articles#nutrition">→</Link>
          </h2>
          <section className="posts">
            {articlesByCategory["Nutrition"].slice(0, 3).map(article => (
              <div
                key={article.slug}
                className="post post-cover-title post-cover-big"
              >
                <Link to={`/${article.slug}`} title={article.title}>
                  <Img
                    fluid={article.titleImage ? article.titleImage.fluid : ``}
                    alt={article.title}
                  />
                </Link>
                <h3>{article.title}</h3>
              </div>
            ))}
          </section>
        </section>

        {/** Veganism */}
        <section className="section-posts">
          <h2>
            Veganism <Link to="/articles#veganism">→</Link>
          </h2>
          <section className="posts">
            {articlesByCategory["Veganism"].slice(0, 3).map(article => (
              <div
                key={article.slug}
                className="post post-cover-title post-cover-big"
              >
                <Link to={`/${article.slug}`} title={article.title}>
                  <Img
                    fluid={article.titleImage ? article.titleImage.fluid : ``}
                    alt={article.title}
                  />
                </Link>
                <h3>{article.title}</h3>
              </div>
            ))}
          </section>
        </section>

        {/** Quote */}
        <aside className="section-big-quote" style={{}}>
          <div className="quote-background">
            <Img fluid={quoteBackground.childImageSharp.fluid} />
          </div>
          <blockquote className="quote">
            "It is a shame for one to grow old without seeing the beauty and
            strength of which their body is capable." - Socrates
          </blockquote>
        </aside>

        {/** Recipes */}
        <section className="section-posts">
          <h2>
            Recipes <Link to="/articles#recipes">→</Link>
          </h2>
          <section className="posts">
            {articlesByCategory["Recipes"].slice(0, 3).map(article => (
              <div
                key={article.slug}
                className="post post-cover-title post-cover-big"
              >
                <Link to={`/${article.slug}`} title={article.title}>
                  <Img
                    fluid={article.titleImage ? article.titleImage.fluid : ``}
                    alt={article.title}
                  />
                </Link>
                <h3>{article.title}</h3>
              </div>
            ))}
          </section>
        </section>

        {/** Athletes */}
        <section className="section-posts">
          <h2>
            Athletes <Link to="/athletes">→</Link>
          </h2>
          <section className="posts">
            {allContentfulInterview.edges
              .slice(0, 3)
              .map(({ node: interview }) => (
                <div
                  key={interview.slug}
                  className="post post-cover-background post-cover-small"
                >
                  <Link
                    to={`/athletes/${interview.slug}`}
                    title={interview.title}
                  >
                    <Img
                      fluid={interview.backgroundImage ? interview.backgroundImage.fluid : ``}
                      alt={interview.title}
                    />
                    <h3>{interview.title}</h3>
                  </Link>
                </div>
              ))}
          </section>
        </section>
      </Container>
    </Layout>
  )
}

export const HomeQuery = graphql`
  query {
    allContentfulArticle(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          category
          contentful_id
          createdAt(formatString: "DD MMM YYYY")
          updatedAt(formatString: "DD MMM YYYY")
          title
          slug
          titleImage {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    quoteBackground: file(relativePath: { eq: "mission-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulInterview {
      edges {
        node {
          contentful_id
          slug
          header
          title
          backgroundImage {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndexPage
